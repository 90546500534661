import { Controller } from "@hotwired/stimulus"
import loadMembers from "../loadMembers"

const waiting = "<div class='spinner'><div class='double-bounce1'></div><div class='double-bounce2'></div></div>"
const tabletWidth = 1024

export default class extends Controller {
  static targets = [ "input", "list", "memberCard", "searchIcon"]

  connect() {
    this.search()
    this.loadingMore = false
    this.searchTimeout = null
  }

  resetList(){
    this.search()
  }

  clearSearch(){
    this.inputTarget.value = ""
    this.search()
  }

  setupReset(){
    if (this.inputTarget.value.length) {
      this.searchIconTarget.classList.add('cancel')
      this.searchIconTarget.classList.remove('search')
    } else {
      this.searchIconTarget.classList.remove('cancel')
      this.searchIconTarget.classList.add('search')
    }
  }

  openTopResult(){
    const topResult = this.listTarget.querySelector("li>a.member")
    if (this.isSearching && topResult && this.desktop()) {
      
      topResult.click()
    }
  }

  search() {
    this.setupReset()
    const $memberList = $(this.listTarget)
    const appendMember = this.appendMember
    if (this.inputTarget.value && this.inputTarget.value.length < 2) {
      return
    }
    this.pageNumber = 1
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout( () => loadMembers(this.inputTarget, this.pageNumber).done(data => {
      $memberList.empty()
      $.each(data, function( index, value ) {
        appendMember($memberList, value)
      });
      this.dispatch("seachLoaded")
    }), 350)
    
  }

  desktop(){
    return window.innerWidth >= tabletWidth
  }

  appendMember($list, member) {
    if ($list.children(`.priority-${member.priority}`).length == 0) {
      $list.append(`<li class="priority-${member.priority}"></li>`)
    }
    $list.append(member.html_tag)
  }

  selectMember() {
    this.memberCardTarget.innerHTML = waiting
  }

  get pageNumber(){
    return parseInt(this.data.get('pageNumber') || '1')
  }

  set pageNumber(value){
    this.data.set('pageNumber', value)
  }

  get loadingMore(){
    return this.data.get('loadingMore')
  }
  set loadingMore(value){
    this.data.set('loadingMore', value)
  }

  get isSearching(){
    return this.inputTarget.value.length > 0
  }

  loadMore(){
    if (this.loadingMore == 'true') {
      return
    }
    const $memberList = $(this.listTarget)
    this.loadingMore = 'true'
    this.pageNumber++
    const appendMember = this.appendMember
    loadMembers(this.inputTarget, this.pageNumber).done(data => {
      this.loadingMore = 'false'
      $.each(data, function( index, value ) {
        appendMember($memberList, value)
      });
    })
  }
  infiniteScroll(event) {
    const element = event.currentTarget
    if (element.scrollHeight - Math.ceil(element.scrollTop) <= element.clientHeight) {
      this.loadMore()
    }
  }

}

