import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage/src/direct_upload";

export default class extends Controller {
  static targets = ["input"];
  static values = { uploadingFiles: Number };


  connect() {
    this.bustCacheIfUploadPreview();
  }

  uploadFile() {
    this.addProgressIndicator();
    this.disableSubmit();

    Array.from(this.inputTarget.files).forEach((file) => {
      this.uploadingFilesValue += 1;
      const upload = new DirectUpload(
        file,
        this.inputTarget.dataset.directUploadUrl,
        this // callback directUploadWillStoreFileWithXHR(request)
      );

      const { id } = upload;

      if (file.type.startsWith("image")) {
        this.addPreview(file, id);
      } else {
        this.addFile(file, id);
      }

      upload.create((error, blob) => {
        if (error) {
          
          this.createError(error);
        } else {
          this.createHiddenBlobInput(blob, id);
          const currentProgress = (this.uploadingFilesValue -= 1);
          const progressElement = document.getElementById(
            `direct-upload-progress`
          );
          if (currentProgress == 0) {
            progressElement.remove();
            this.enableSubmit();
            this.markUploadsComplete();
            
          }
        }
      });
    });
    this.inputTarget.value = "";
  }

  attachRemoteMedia(event) {
    this.clearAnyUploads();
    
    const medias = JSON.parse(event.target.value);
    event.target.value = null;
    const inputName = event.target.name;
    
    let count = 0;
    medias.forEach((media) => {
      const id = count++;
      const file = new File([], media.filename, { type: media.content_type });
      this.createHiddenRemoteMediaInput(media, id, inputName);
      if (media.content_type.startsWith("image")) {
        this.addPreview(file, id, media.preview_url);
      } else {
        this.addFile(file, id);
      }
    });
    this.markUploadsInProgress();
    this.markUploadsComplete();
  }

  // add blob id to be submitted with the form
  createHiddenBlobInput(blob, id) {
    const hiddenField = document.createElement("input");
    hiddenField.id = `direct-upload-value-${id}`;
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("value", blob.signed_id);
    hiddenField.name = this.inputTarget.name;
    this.element.appendChild(hiddenField);
    
  }

  createHiddenRemoteMediaInput(media, id, name) {
    const hiddenField = document.createElement("input");
    hiddenField.id = `direct-upload-value-${id}`;
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("value", media.signed_id);
    hiddenField.name = name;
    this.element.appendChild(hiddenField);
  }

  createError(error) {
    const element = document.createElement("div");
    element.classList.add("direct-upload", "direct-upload--error");
    element.innerText = error;
    this.element.appendChild(element);
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => {
      
      this.progressUpdate(event);
    });
  }

  progressUpdate(event) {
    const progress = (event.loaded / event.total) * 100;
    
    const progressElement = document.getElementById(`direct-upload-progress`);
    const totalProgress = progress / this.uploadingFilesValue;
    
    this.markUploadsInProgress();
    progressElement.style.width = `${totalProgress}%`;
    if (progress == 100) {
    }
  }

  bustCacheIfUploadPreview() {
    const hasPreview = !!document.querySelector('.direct-upload--preview')
    if (hasPreview) {
      const url = window.location.href
      console.log(url)
      Turbo.visit(url, { action: 'replace', cache: false })
    }
  }

  addFile(file, id, type) {
    const element = document.createElement("div");
    element.classList.add(
      "cf",
      "direct-upload",
      "direct-upload--file",
      "direct-upload--pending"
    );
    element.id = `direct-upload-${id}`;
    element.dataset.fileType = type || file.type;
    const placeholder = document.createElement("div");
    placeholder.classList.add("direct-upload--file-placeholder");
    element.appendChild(placeholder);
    this.addLinks(file, element, id);
    this.element.appendChild(element);
  }

  addPreview(file, id, src) {
    const element = document.createElement("div");
    element.id = `direct-upload-${id}`;
    element.classList.add(
      "cf",
      "direct-upload",
      "direct-upload--preview",
      "direct-upload--pending"
    );
    const img = document.createElement("img");
    img.classList.add("preview");
    img.src = src || URL.createObjectURL(file);
    element.appendChild(img);
    this.addLinks(file, element, id);
    this.element.appendChild(element);
  }

  addLinks(file, element, id) {
    const container = document.createElement("div");
    container.classList.add("direct-upload--links");
    const filename = document.createElement("span");
    filename.classList.add("direct-upload--filename");
    filename.textContent = file.name;
    container.appendChild(filename);
    const remove = document.createElement("span");
    remove.classList.add("direct-upload--remove");
    remove.textContent = "Remove";
    remove.addEventListener("click", (event) => {
      event.preventDefault();
      this.removePreview(id);
    });
    container.appendChild(remove);
    element.appendChild(container);
  }

  removePreview(id) {
    const element = document.getElementById(`direct-upload-${id}`);
    element.remove();
    const input = document.getElementById(`direct-upload-value-${id}`);
    input.remove();
  }

  addProgressIndicator() {
    const element = document.createElement("div");
    
    
    element.id = `direct-upload-progress`;
    element.style.width = `0%`;
    this.element.appendChild(element);
  }

  clearAnyUploads() {
    const elements = this.element.querySelectorAll(".direct-upload, input[id^='direct-upload-value-']");
    elements.forEach((e) => e.remove());
  }

  startUploadIndicator(file, id) {
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.remove("direct-upload--pending");
  }

  markUploadsInProgress() {
    this.element
      .querySelectorAll(".direct-upload.direct-upload--pending")
      .forEach((e) => e.classList.add("direct-upload--progress"));
  }

  markUploadsComplete() {
    this.element
      .querySelectorAll(".direct-upload.direct-upload--progress")
      .forEach((e) => e.classList.add("direct-upload--complete"));
  }

  disableSubmit() {
    this.element.dataset.uploading = true
    this._btn.classList.add("disabled");
    this._btn.disable = true;
    const event = new CustomEvent("uploading", { bubbles: true });
    this.element.dispatchEvent(event);
    
  }

  enableSubmit() {
    this.element.dataset.uploading = false
    this._btn.classList.remove("disabled");
    this._btn.disable = false;
    const event = new CustomEvent("uploadingComplete", { bubbles: true });
    this.element.dispatchEvent(event);
    
  }

  get _form() {
    return this.element.closest("form");
  }

  get _btn() {
    return (
      document.querySelector(
        "#notification-compose .ui.positive.right.button"
      ) ||
      this.element.querySelector(".ui.positive.right.button") || // for modals
      this._form.querySelector("input[type='submit']") ||
      this._form.querySelector("button[type='submit']") ||
      this._form.parentElement.querySelector("button[type='submit']") // for community notifications wizard.
    );
  }
}
