import { Controller } from "@hotwired/stimulus";

const uuidv4 = require('uuid/v4');

export default class extends Controller {
  static targets = [ "scope", "status", "player", 'audioUrl', 'fileField', 'progressIndicator', 'progressLine']
  static values = { signedUrl: String, authToken: String }

  connect(){
  }

  async upload(event){
    const file = event.target.files[0]
    const name = uuidv4() + '-' + encodeURIComponent(file.name.split(" ").join('-'))
    this.statusTarget.innerText = "Uploading"
    this.disableSubmit()
    await this._uploadSignedS3(file, name, event.target)
  }

  uploadComplete(event){
    this.statusTarget.innerText = ""
    this.statusTarget.innerText = "Processing audio file, this may take several seconds"
    this.progressIndicatorTarget.classList.add('no-show')
    const pathName = event.detail
    const bucketUrl = "https://s3.amazonaws.com/connect-rocket-audio/"
    const filename = pathName.split('.')[0]
    const extension = pathName.split('.')[1]
    const mp3Url = bucketUrl + filename + ".mp3"
    this.checkForProcessedMp3File(mp3Url)
  }

  checkForProcessedMp3File(mp3Url, tryAgain = this.checkForProcessedMp3File, audioUrl = this.audioUrlTarget, fileField = this.fileFieldTarget){
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', mp3Url, true);
    xhr.onreadystatechange = function(e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          audioUrl.value = mp3Url
          if (fileField) {
            fileField.value = ''
          }
          const change = new CustomEvent('change', {bubbles: true});
          audioUrl.dispatchEvent(change);
        } else {
          setTimeout(tryAgain, 500, mp3Url, tryAgain, audioUrl);
        }
      }
    }
    xhr.send();
  }

  loadPlayer() {
    const statusTarget = this.statusTarget
    this.statusTarget.innerText = ""
    const audioUrlTarget = this.audioUrlTarget
    const fileName = this.audioUrlTarget.value
    const players = this.playerTarget
    this.enableSubmit()
    const audioPlayer = document.createElement("audio");
    this.playerTarget.innerHTML = ''
    if (!audioUrlTarget.value) {
      return
    }
    audioPlayer.className = "audio-player";
    audioPlayer.src = audioUrlTarget.value
    audioPlayer.setAttribute("controls", "")
    audioPlayer.oncanplay = function() {
      statusTarget.innerText = '';
      players.appendChild(audioPlayer)
      const trash = document.createElement('span')
      trash.dataset.action = "click->audio-upload#removeAudio"
      trash.innerText = "Remove"
      trash.classList.add('remove-audio')
      players.appendChild(trash)
    }
  }

  disableSubmit() {
    this.isExistingButtonValid = !this._btn.classList.contains('disabled')
    this._btn.classList.add('disabled')
  }

  enableSubmit(){
    if (this.isExistingButtonValid) {
      this._btn.classList.remove('disabled')
    }
  }

  removeAudio(){
    this.playerTarget.innerHTML = ''
    this.audioUrlTarget.value = ''
    this.fileFieldTarget.value = ''
  }

  updateProgress(event){
    const percentComplete = event.detail.progress
    console.log(percentComplete)
    this.progressIndicatorTarget.classList.remove('no-show')
    this.progressLineTarget.style.width = `${percentComplete}%`
  }

  async _uploadSignedS3(file, file_name, target) {
    const prefix = 'audio-recordings/'
    const key = prefix + file_name;
    const progressIndicator = this.progressIndicatorTarget
    progressIndicator.classList.remove('no-show')
    const uploadUrl = await this._uploadUrl(key, file.type)

    console.log(uploadUrl)
    // Upload with progress tracking
    await this._uploadWithProgress(uploadUrl, file)
    const event = new CustomEvent('s3UploadComplete', {bubbles: true, cancelable: true, detail: key})
    target.dispatchEvent(event);
  }

  async _uploadUrl(key, contentType){
    const authToken = this.authToken
    const bucket = 'connect-rocket-audio'
    const region = 'us-east-1' // This is the default region for audio processing

    const request = await fetch(this.signedUrlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": authToken
      },
      body: JSON.stringify({ key, contentType, bucket, region }),
    })
    const response = await request.json()
    console.log(response)
    return response.uploadURL
  }

  _uploadWithProgress(url, file) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100
          this.dispatch("uploadProgress", { 
            detail: { progress: percentComplete } 
          })
        }
      })
  
      xhr.addEventListener('load', () => {
        if (xhr.status === 200) {
          resolve()
        } else {
          reject(new Error(`Upload failed: ${xhr.status}`))
        }
      })
  
      xhr.addEventListener('error', () => reject(new Error('Upload failed')))
      
      xhr.open('PUT', url)
      xhr.setRequestHeader('Content-Type', file.type)
      xhr.send(file)
    })
  }

  get _btn(){
    return document.querySelector('#notification-compose .ui.positive.right.button') || this.element.querySelector('.ui.positive.right.button') || this.element.querySelector("input[type='submit']") || this.element.querySelector("button[type='submit']")
  }

  get authToken(){
    if (this.hasAuthTokenValue) {
      return this.authTokenValue
    }
    const tag = document.querySelector("[data-audio-upload-token]")
    return tag.dataset.audioUploadToken
  }
}
